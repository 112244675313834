import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/atoms/GlobalLynx.module.scss");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/atoms/GlobalLynx.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/blueicons/analisis-bia-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/blueicons/analisis-iso-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/blueicons/analisis-riesgos.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/blueicons/audit-iso-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/blueicons/auditoria-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/blueicons/base-de-datos-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/blueicons/bcp-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/blueicons/bia-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/blueicons/brujula-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/blueicons/cambio-organizacional-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/blueicons/capacitacion-dos-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/blueicons/capacitacion-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/blueicons/certificacion-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/blueicons/controles-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/blueicons/devops-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/blueicons/devsecops-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/blueicons/drp-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/blueicons/estrategia-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/blueicons/evaluacion-devops-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/blueicons/evaluacion-dos-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/blueicons/evaluacion-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/blueicons/evaluacion-madurez-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/blueicons/evaluacion-riesgos-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/blueicons/identificar-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/blueicons/implementacion-devops-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/blueicons/implementacion-dos-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/blueicons/implementacion-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/blueicons/implementacion-iso-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/blueicons/modelo-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/blueicons/modelo-riesgos-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/blueicons/organizacion-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/blueicons/planes-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/blueicons/politicas-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/blueicons/pruebas-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/blueicons/quotes-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/blueicons/recomendaciones-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/blueicons/riesgos-negocio-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/cards/agile-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/cards/architecture-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/cards/arquitectura-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/cards/audit-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/cards/business-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/cards/cert-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/cards/cibersecurity-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/cards/cloud-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/cards/combos-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/cards/cons-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/cards/continuity-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/cards/courses-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/cards/development-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/cards/devops-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/cards/duration-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/cards/include-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/cards/management-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/cards/modality-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/cards/online-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/cards/organization-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/cards/person-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/cards/pipeline-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/cards/planning-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/cards/practices-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/cards/process-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/cards/regulation-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/cards/risk-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/cards/roadmap-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/cards/security-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/cards/technology-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/cards/timing-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/fontawesome/angle-down.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/fontawesome/angles-left.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/fontawesome/angles-right.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/fontawesome/arrow-left.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/fontawesome/arrow-right.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/fontawesome/arrow-up-right.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/fontawesome/briefcase-solid.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/fontawesome/briefcase.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/fontawesome/calendar.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/fontawesome/caret-down.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/fontawesome/caret-left.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/fontawesome/caret-right.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/fontawesome/caret-up.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/fontawesome/certificate.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/fontawesome/certification.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/fontawesome/chalkboard-user.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/fontawesome/check-bold.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/fontawesome/check.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/fontawesome/chevron-down.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/fontawesome/chevron-left.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/fontawesome/chevron-right.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/fontawesome/chevron-up.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/fontawesome/circle-check.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/fontawesome/circle-user.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/fontawesome/circle.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/fontawesome/clock-regular.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/fontawesome/clock-rotate-left.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/fontawesome/close.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/fontawesome/copy.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/fontawesome/diagram-project.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/fontawesome/download.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/fontawesome/email.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/fontawesome/eye-slash.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/fontawesome/eye.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/fontawesome/facebook.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/fontawesome/fecha.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/fontawesome/folder.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/fontawesome/info.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/fontawesome/instagram.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/fontawesome/linkedin.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/fontawesome/location-dot.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/fontawesome/location.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/fontawesome/menu.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/fontawesome/phone-solid.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/fontawesome/plus.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/fontawesome/quote-left.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/fontawesome/search.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/fontawesome/share.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/fontawesome/speaker.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/fontawesome/star.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/fontawesome/tag.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/fontawesome/twitter.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/fontawesome/user.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/fontawesome/whatsapp.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/fontawesome/youtube.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/logo/global-lynx-blog.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/logo/global-lynx-blue.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/logo/global-lynx.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/logo/isotipo.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/logo/logo-large.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/logo/logo-small.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/logo/making-it-better.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/simpleicons/bookmark-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/simpleicons/calendar-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/simpleicons/clock-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/simpleicons/cookie-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/simpleicons/location-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/simpleicons/mail-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/simpleicons/phone-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/simpleicons/sandclock-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/icons/simpleicons/world-icon.svg");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/objects/cards/ImageCard.module.scss");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/molecules/Table.module.scss");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/sliders/HomeSlider.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/components/web-forms/lead/PagesContactForm.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src2357151547/src/globallynx-mx/node_modules/next/dist/client/link.js")